var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    !_vm.fetchingInstances
      ? _c(
          "div",
          [
            _c(
              "div",
              { staticClass: "d-flex flex-wrap align-center" },
              [
                _vm._l(
                  Object.keys(_vm.userCheckboxBinders),
                  function (item, index) {
                    return _c("v-checkbox", {
                      key: index,
                      staticClass: "mx-4",
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "label",
                            fn: function () {
                              return [
                                _c(
                                  "div",
                                  { staticClass: "d-flex align-center" },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "mr-1",
                                        attrs: {
                                          small: "",
                                          color: "secondary",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.userCheckboxBinders[item].icon
                                          )
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "secondary--text caption text-uppercase",
                                      },
                                      [_vm._v(_vm._s(item))]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                      model: {
                        value: _vm.userCheckboxBinders[item].status,
                        callback: function ($$v) {
                          _vm.$set(_vm.userCheckboxBinders[item], "status", $$v)
                        },
                        expression: "userCheckboxBinders[item].status",
                      },
                    })
                  }
                ),
                _c("v-spacer"),
                _c("v-text-field", {
                  staticClass: "mr-2",
                  attrs: {
                    autofocus: "",
                    label: "Filter",
                    solo: "",
                    flat: "",
                    "background-color": "grey lighten-4",
                    dense: "",
                    "hide-details": "",
                    clearable: "",
                    "prepend-inner-icon": "mdi-filter",
                  },
                  model: {
                    value: _vm.instanceSearch,
                    callback: function ($$v) {
                      _vm.instanceSearch = $$v
                    },
                    expression: "instanceSearch",
                  },
                }),
                _c(
                  "v-tooltip",
                  {
                    attrs: { bottom: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function ({ on }) {
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  {
                                    attrs: { text: "", icon: "" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$store.dispatch(
                                          "spaceStore/fetchSpaceInstances",
                                          _vm.$route.params.sid
                                        )
                                      },
                                    },
                                  },
                                  on
                                ),
                                [_c("v-icon", [_vm._v("refresh")])],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3106773887
                    ),
                  },
                  [_c("span", [_vm._v("refresh")])]
                ),
              ],
              2
            ),
            _c("v-divider", { staticClass: "mb-5" }),
            _c("v-data-iterator", {
              attrs: { items: _vm.spaceInstances },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _vm._l(props.items, function (item, index) {
                          return _c(
                            "div",
                            { key: item.iid },
                            [
                              index > 0
                                ? _c("v-divider", {
                                    staticClass: "mb-2",
                                    staticStyle: {
                                      opacity: "0.25",
                                      "border-width": "thin",
                                    },
                                    attrs: { inset: "" },
                                  })
                                : _vm._e(),
                              _c(
                                "v-list-item",
                                { attrs: { "two-line": "" } },
                                [
                                  _c(
                                    "v-row",
                                    { attrs: { align: "center" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "1" } },
                                        [
                                          _c(
                                            "v-list-item-avatar",
                                            [
                                              item.long_id ===
                                              _vm.instanceFixedNames
                                                .MASTER_INSTANCE
                                                ? _c(
                                                    "v-icon",
                                                    {
                                                      attrs: { "x-large": "" },
                                                    },
                                                    [_vm._v("star")]
                                                  )
                                                : item.long_id ===
                                                  _vm.instanceFixedNames
                                                    .DISTRIBUTED_INSTANCE
                                                ? _c(
                                                    "v-icon",
                                                    {
                                                      attrs: { "x-large": "" },
                                                    },
                                                    [_vm._v("share")]
                                                  )
                                                : _c(
                                                    "v-icon",
                                                    {
                                                      attrs: { "x-large": "" },
                                                    },
                                                    [_vm._v("group")]
                                                  ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "5" } },
                                        [
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c(
                                                "v-list-item-title",
                                                [
                                                  _c(
                                                    "router-link",
                                                    {
                                                      staticStyle: {
                                                        "text-decoration":
                                                          "none",
                                                      },
                                                      attrs: {
                                                        to: {
                                                          name: "snapshot-overview",
                                                          params: {
                                                            oid: _vm.$route
                                                              .params.oid,
                                                            sid: _vm.$route
                                                              .params.sid,
                                                            iid: item.iid,
                                                            snid: item.dev_snid,
                                                          },
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("v-hover", {
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "default",
                                                              fn: function ({
                                                                hover,
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      class: [
                                                                        hover
                                                                          ? "secondary--text"
                                                                          : "secondary--text",
                                                                        "font-weight-medium",
                                                                      ],
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            item.long_id
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c("v-list-item-subtitle", [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "caption mr-3",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(item.description)
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "3" } },
                                        [
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c(
                                                "v-list-item-subtitle",
                                                {
                                                  staticStyle: { width: "30%" },
                                                },
                                                [
                                                  _c(
                                                    "ul",
                                                    {
                                                      staticStyle: {
                                                        "list-style-type":
                                                          "none",
                                                      },
                                                    },
                                                    [
                                                      _vm.spaceAdmins.length &&
                                                      _vm.userCheckboxBinders
                                                        .administrators.status
                                                        ? _c(
                                                            "div",
                                                            _vm._l(
                                                              _vm.spaceAdmins,
                                                              function (admin) {
                                                                return _c(
                                                                  "ComponentWithStyledBorder",
                                                                  {
                                                                    key: admin.ucid,
                                                                  },
                                                                  [
                                                                    _c("li", [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "d-flex align-center",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              staticClass:
                                                                                "mr-1 ml-1",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "security"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                admin.full_name
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm.instancesWithNonAdminRole.includes(
                                                        item.long_id
                                                      )
                                                        ? _c(
                                                            "div",
                                                            [
                                                              _c(
                                                                "ComponentWithStyledBorder",
                                                                _vm._l(
                                                                  _vm.filterInstanceRolesPerInstance(
                                                                    item.long_id
                                                                  ),
                                                                  function (
                                                                    role,
                                                                    index
                                                                  ) {
                                                                    return _c(
                                                                      "li",
                                                                      {
                                                                        key: index,
                                                                      },
                                                                      [
                                                                        role.Instance ===
                                                                        item.long_id
                                                                          ? _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "d-flex align-center",
                                                                              },
                                                                              [
                                                                                role.role_name ===
                                                                                _vm
                                                                                  .roleTypes
                                                                                  .INSTANCE_VIEWER
                                                                                  ? _c(
                                                                                      "v-icon",
                                                                                      {
                                                                                        staticClass:
                                                                                          "mr-1 ml-1",
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          " visibility "
                                                                                        ),
                                                                                      ]
                                                                                    )
                                                                                  : role.role_name ===
                                                                                    _vm
                                                                                      .roleTypes
                                                                                      .INSTANCE_EDITOR
                                                                                  ? _c(
                                                                                      "v-icon",
                                                                                      {
                                                                                        staticClass:
                                                                                          "mr-1 ml-1",
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "edit"
                                                                                        ),
                                                                                      ]
                                                                                    )
                                                                                  : role.role_name ===
                                                                                    _vm
                                                                                      .roleTypes
                                                                                      .INSTANCE_OBSERVER
                                                                                  ? _c(
                                                                                      "v-icon",
                                                                                      {
                                                                                        staticClass:
                                                                                          "mr-1 ml-1",
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          " visibility_off "
                                                                                        ),
                                                                                      ]
                                                                                    )
                                                                                  : _vm._e(),
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      role.full_name
                                                                                    ) +
                                                                                    " "
                                                                                ),
                                                                              ],
                                                                              1
                                                                            )
                                                                          : _vm._e(),
                                                                      ]
                                                                    )
                                                                  }
                                                                ),
                                                                0
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          staticClass:
                                                            "secondary--text justify-start",
                                                          attrs: {
                                                            small: "",
                                                            text: "",
                                                            block: "",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.inviteToInstance(
                                                                item.long_id
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                small: "",
                                                                color:
                                                                  "primary",
                                                              },
                                                            },
                                                            [_vm._v("add")]
                                                          ),
                                                          _vm._v(
                                                            " Invite users "
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-action",
                                    [
                                      _c("InstanceDeleteDialog", {
                                        attrs: {
                                          instanceId: parseInt(item.iid, 10),
                                          instanceName: item.long_id,
                                          instanceDescription: item.description,
                                          masterInstanceId:
                                            _vm.masterInstance.iid.toString(),
                                          masterDevelopmentSnapshotId:
                                            _vm.masterInstance.dev_snid.toString(),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        _c(
                          "add-instance-dialog",
                          [
                            _c(
                              "v-list",
                              { attrs: { nav: "" } },
                              [
                                _c(
                                  "v-list-item",
                                  {
                                    attrs: {
                                      to: { name: _vm.$router.name },
                                      "active-class": "activeClass",
                                      "two-line": "",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-row",
                                      { attrs: { align: "center" } },
                                      [
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "1" } },
                                          [
                                            _c(
                                              "v-list-item-avatar",
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { "x-large": "" } },
                                                  [_vm._v("add")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "3" } },
                                          [
                                            _c(
                                              "v-list-item-content",
                                              [
                                                _c("v-list-item-title", [
                                                  _vm._v("Add new instance"),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                4085301666
              ),
            }),
          ],
          1
        )
      : _vm.fetchingInstances
      ? _c(
          "div",
          _vm._l(
            [
              { id: "c1", opacity: 1 },
              { id: "c2", opacity: 0.75 },
              { id: "c3", opacity: 0.5 },
            ],
            function (item) {
              return _c("div", { key: item.id, staticClass: "my-6" }, [
                _c(
                  "div",
                  { style: { opacity: item.opacity } },
                  [
                    _c("v-skeleton-loader", {
                      staticClass: "mx-auto",
                      attrs: { type: "list-item-avatar-two-line" },
                    }),
                  ],
                  1
                ),
              ])
            }
          ),
          0
        )
      : _c(
          "div",
          [
            _c(
              "v-banner",
              {
                staticClass: "mb-5",
                attrs: { "single-line": "" },
                scopedSlots: _vm._u([
                  {
                    key: "actions",
                    fn: function () {
                      return [
                        _c(
                          "add-instance-dialog",
                          [
                            _c(
                              "v-btn",
                              { attrs: { color: "primary" } },
                              [
                                _c("v-icon", { attrs: { small: "" } }, [
                                  _vm._v("add"),
                                ]),
                                _c(
                                  "span",
                                  { staticClass: "font-weight-bold caption" },
                                  [_vm._v("Add New Instance")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              },
              [
                _c(
                  "v-avatar",
                  {
                    attrs: { slot: "icon", color: "white", size: "30" },
                    slot: "icon",
                  },
                  [
                    _c("v-icon", { attrs: { color: "info" } }, [
                      _vm._v("info"),
                    ]),
                  ],
                  1
                ),
                _c("div", { staticClass: "d-flex flex-column" }, [
                  _c(
                    "span",
                    { staticClass: "secondary--text font-weight-bold" },
                    [_vm._v("No instances found")]
                  ),
                ]),
              ],
              1
            ),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }